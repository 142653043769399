import { defineAsyncComponent } from "vue";

const LfInput = defineAsyncComponent(
  () => import("@/components/ui/inputs/LfInput.vue")
);

const SearchInput = defineAsyncComponent(
  () => import("@/components/ui/inputs/SearchInput.vue")
);

const LfLink = defineAsyncComponent(() => import("@/components/ui/LfLink.vue"));

const LfTextarea = defineAsyncComponent(
  () => import("@/components/ui/inputs/LfTextarea.vue")
);

const IconBase = defineAsyncComponent(
  () => import("@/components/ui/IconBase.vue")
);

const PrimaryButton = defineAsyncComponent(
  () => import("@/components/ui/buttons/PrimaryButton.vue")
);

const OutlineButton = defineAsyncComponent(
  () => import("@/components/ui/buttons/OutlineButton.vue")
);

const LfDatePicker = defineAsyncComponent(
  () => import("@/components/ui/inputs/LfDatePicker.vue")
);

const LfDetails = defineAsyncComponent(
  () => import("@/components/ui/LfDetails.vue")
);

const DateTimeInput = defineAsyncComponent(
  () => import("@/components/ui/inputs/DateTimeInput.vue")
);

const PaymentFrequencyDropdown = defineAsyncComponent(
  () => import("@/views/deals/components/PaymentFrequencyDropdown.vue")
);

const LfButton = defineAsyncComponent(
  () => import("@/components/ui/buttons/LfButton.vue")
);

const ColorButton = defineAsyncComponent(
  () => import("@/components/ui/buttons/ColorButton.vue")
);

const LfTab = defineAsyncComponent(() => import("@/components/ui/LfTab.vue"));

const LfHeading1 = defineAsyncComponent(
  () => import("@/components/ui/text/LfHeading1.vue")
);

const LfHeading2 = defineAsyncComponent(
  () => import("@/components/ui/text/LfHeading2.vue")
);

const LfHeading3 = defineAsyncComponent(
  () => import("@/components/ui/text/LfHeading3.vue")
);

const LfHeading4 = defineAsyncComponent(
  () => import("@/components/ui/text/LfHeading4.vue")
);

const LfPill = defineAsyncComponent(
  () => import("@/components/ui/text/LfPill.vue")
);

const StatusPill = defineAsyncComponent(
  () => import("@/components/ui/StatusPill.vue")
);

const OfferStatusPill = defineAsyncComponent(
  () => import("@/components/ui/OfferStatusPill.vue")
);

const OfferPillDropdown = defineAsyncComponent(
  () => import("@/components/ui/OfferPillDropdown.vue")
);

const OfferContactedVia = defineAsyncComponent(
  () => import("@/components/ui/OfferContactedVia.vue")
);

const OfferDeleteButton = defineAsyncComponent(
  () => import("@/components/ui/buttons/OfferDeleteButton.vue")
);

const PlacementStatus = defineAsyncComponent(
  () => import("@/components/ui/PlacementStatus.vue")
);

const LfPieChart = defineAsyncComponent(
  () => import("@/components/ui/LfPieChart.vue")
);

const AssignUserField = defineAsyncComponent(
  () => import("@/views/deals/components/AssignUserField.vue")
);

const ButtonCreateOffer = defineAsyncComponent(
  () => import("@/views/deals/components/ButtonCreateOffer.vue")
);

const ButtonEditOffer = defineAsyncComponent(
  () => import("@/views/deals/components/ButtonEditOffer.vue")
);

const OfferDropdownMenu = defineAsyncComponent(
  () => import("@/views/deals/components/OfferDropdownMenu.vue")
);

const LfDate = defineAsyncComponent(() => import("@/components/ui/LfDate.vue"));

const LfDropdown = defineAsyncComponent(
  () => import("@/components/ui/inputs/LfDropdown.vue")
);

const LfDropdownWithApiSearch = defineAsyncComponent(
  () => import("@/components/ui/inputs/LfDropdownWithApiSearch.vue")
);

const LfCheckboxDropdown = defineAsyncComponent(
  () => import("@/components/ui/inputs/LfCheckboxDropdown.vue")
);

const LfBadge = defineAsyncComponent(
  () => import("@/components/ui/LfBadge.vue")
);

const LfChip = defineAsyncComponent(() => import("@/components/ui/LfChip.vue"));

const LfStatus = defineAsyncComponent(
  () => import("@/components/ui/LfStatus.vue")
);

const EditButton = defineAsyncComponent(
  () => import("@/components/ui/buttons/EditButton.vue")
);

const LfCurrency = defineAsyncComponent(
  () => import("@/components/ui/inputs/LfCurrency.vue")
);

const DocumentsListItemActionBtn = defineAsyncComponent(
  () => import("@/components/deals/documents/DocumentsListItemActionBtn.vue")
);

const UserProfile = defineAsyncComponent(
  () => import("@/components/UserProfile.vue")
);

const EditRowButton = defineAsyncComponent(
  () => import("@/components/ui/EditRowButton.vue")
);

const ClientModal = defineAsyncComponent(
  () => import("@/views/profile/modals/ClientModal.vue")
);

const UserModal = defineAsyncComponent(
  () => import("@/views/profile/modals/UserModal.vue")
);

const AssignWorkflowDropdown = defineAsyncComponent(
  () => import("@/views/profile/components/AssignWorkflowDropdown.vue")
);

const Loader = defineAsyncComponent(() => import("@/components/ui/Loader.vue"));

const LfExternalLink = defineAsyncComponent(
  () => import("@/components/ui/text/LfExternalLink.vue")
);

const AccessControl = defineAsyncComponent(
  () => import("@/components/AccessControl.vue")
);

const LfCard = defineAsyncComponent(() => import("@/components/ui/LfCard.vue"));

const LfScrollableMenu = defineAsyncComponent(
  () => import("@/components/ui/LfScrollableMenu.vue")
);

const RoundButton = defineAsyncComponent(
  () => import("@/components/ui/buttons/RoundButton.vue")
);

const CircleProgress = defineAsyncComponent(
  () => import("@/components/ui/CircleProgress.vue")
);

const Progress = defineAsyncComponent(
  () => import("@/components/ui/Progress.vue")
);

const FunderModal = defineAsyncComponent(
  () => import("@/components/funders/FunderModal.vue")
);

const FunderModalTableButton = defineAsyncComponent(
  () => import("@/components/funders/FunderModalTableButton.vue")
);

const FunderUserModal = defineAsyncComponent(
  () => import("@/views/profile/modals/FunderUserModal.vue")
);

const ContactModal = defineAsyncComponent(
  () => import("@/components/funders/ContactModal.vue")
);

const ContactModalTableButton = defineAsyncComponent(
  () => import("@/components/funders/ContactModalTableButton.vue")
);

const SalesRepModal = defineAsyncComponent(
  () => import("@/views/profile/modals/SalesRepModal.vue")
);

const SalesRepModalTableButton = defineAsyncComponent(
  () => import("@/views/profile/components/SalesRepModalTableButton.vue")
);

const DownloadServiceButton = defineAsyncComponent(
  () =>
    import("@/views/businessCreditDeals/components/DownloadServiceButton.vue")
);

const ServiceWrapper = defineAsyncComponent(
  () => import("@/components/deals/dataServices/ServiceWrapper.vue")
);

const LfSwitch = defineAsyncComponent(
  () => import("@/components/ui/inputs/LfSwitch.vue")
);

const LfButtonToggle = defineAsyncComponent(
  () => import("@/components/ui/inputs/LfButtonToggle.vue")
);

const IconRiskTier1 = defineAsyncComponent(
  () => import("@/components/icons/risk-tiers/IconRiskTier1.vue")
);

const IconFormulaView = defineAsyncComponent(
  () => import("@/components/icons/IconFormulaView.vue")
);

const IconRiskTier2 = defineAsyncComponent(
  () => import("@/components/icons/risk-tiers/IconRiskTier2.vue")
);

const IconRiskTier3 = defineAsyncComponent(
  () => import("@/components/icons/risk-tiers/IconRiskTier3.vue")
);

const IconRiskTier4 = defineAsyncComponent(
  () => import("@/components/icons/risk-tiers/IconRiskTier4.vue")
);

const IconRiskTier5 = defineAsyncComponent(
  () => import("@/components/icons/risk-tiers/IconRiskTier5.vue")
);

const IconRiskTier6 = defineAsyncComponent(
  () => import("@/components/icons/risk-tiers/IconRiskTier6.vue")
);

const IconClose = defineAsyncComponent(
  () => import("@/components/icons/IconClose.vue")
);

const IconFundedOffer = defineAsyncComponent(
  () => import("@/components/icons/IconFundedOffer.vue")
);

const IconEmailEnabled = defineAsyncComponent(
  () => import("@/components/icons/IconEmailEnabled.vue")
);

const IconEmailDisabled = defineAsyncComponent(
  () => import("@/components/icons/IconEmailDisabled.vue")
);

const IconId = defineAsyncComponent(
  () => import("@/components/icons/IconId.vue")
);

const IconExpand = defineAsyncComponent(
  () => import("@/components/icons/IconExpand.vue")
);

const IconExpandWhite = defineAsyncComponent(
  () => import("@/components/icons/IconExpandWhite.vue")
);

const IconReset = defineAsyncComponent(
  () => import("@/components/icons/IconReset.vue")
);

const IconRestart = defineAsyncComponent(
  () => import("@/components/icons/IconRestart.vue")
);

const IconShrink = defineAsyncComponent(
  () => import("@/components/icons/IconShrink.vue")
);

const IconShrinkWhite = defineAsyncComponent(
  () => import("@/components/icons/IconShrinkWhite.vue")
);

const IconSort = defineAsyncComponent(
  () => import("@/components/icons/IconSort.vue")
);
const IconSortArrow = defineAsyncComponent(
  () => import("@/components/icons/IconSortArrow.vue")
);

const IconSortArrowActive = defineAsyncComponent(
  () => import("@/components/icons/IconSortArrowActive.vue")
);

const IconLogo = defineAsyncComponent(
  () => import("@/components/icons/IconLogo.vue")
);

const IconLogoBlue = defineAsyncComponent(
  () => import("@/components/icons/IconLogoBlue.vue")
);

const IconLogoWhite = defineAsyncComponent(
  () => import("@/components/icons/IconLogoWhite.vue")
);

const IconChevronRight = defineAsyncComponent(
  () => import("@/components/icons/IconChevronRight.vue")
);

const IconLogoExpanded = defineAsyncComponent(
  () => import("@/components/icons/IconLogoExpanded.vue")
);

const IconLogoExpandedBlack = defineAsyncComponent(
  () => import("@/components/icons/IconLogoExpandedBlack.vue")
);

const IconCopy = defineAsyncComponent(
  () => import("@/components/icons/IconCopy.vue")
);

const IconCheckCircle = defineAsyncComponent(
  () => import("@/components/icons/IconCheckCircle.vue")
);

const IconCheckedGray = defineAsyncComponent(
  () => import("@/components/icons/IconCheckedGray.vue")
);

const IconShowPass = defineAsyncComponent(
  () => import("@/components/icons/IconShowPass.vue")
);

const IconCurlyBraces = defineAsyncComponent(
  () => import("@/components/icons/IconCurlyBraces.vue")
);

const IconShowPassNoBg = defineAsyncComponent(
  () => import("@/components/icons/IconShowPassNoBg.vue")
);

const IconHidePass = defineAsyncComponent(
  () => import("@/components/icons/IconHidePass.vue")
);

const IconCopyFilled = defineAsyncComponent(
  () => import("@/components/icons/IconCopyFilled.vue")
);

const IconDownload = defineAsyncComponent(
  () => import("@/components/icons/IconDownload.vue")
);

const IconDownloadLarge = defineAsyncComponent(
  () => import("@/components/icons/IconDownloadLarge.vue")
);

const IconDelete = defineAsyncComponent(
  () => import("@/components/icons/IconDelete.vue")
);

const IconDeleteRound = defineAsyncComponent(
  () => import("@/components/icons/IconDeleteRound.vue")
);

const IconDeleteOffer = defineAsyncComponent(
  () => import("@/components/icons/IconDeleteOffer.vue")
);

const IconVerticalDots = defineAsyncComponent(
  () => import("@/components/icons/IconVerticalDots.vue")
);

const IconFile = defineAsyncComponent(
  () => import("@/components/icons/IconFile.vue")
);

const IconLogout = defineAsyncComponent(
  () => import("@/components/icons/IconLogout.vue")
);

const IconEditWhite = defineAsyncComponent(
  () => import("@/components/icons/IconEditWhite.vue")
);

const IconRoundIncrease = defineAsyncComponent(
  () => import("@/components/icons/IconRoundIncrease.vue")
);

const IconRoundDecrease = defineAsyncComponent(
  () => import("@/components/icons/IconRoundDecrease.vue")
);

const IconRoundNeutral = defineAsyncComponent(
  () => import("@/components/icons/IconRoundNeutral.vue")
);

const IconPlus = defineAsyncComponent(
  () => import("@/components/icons/IconPlus.vue")
);

const IconPlusBlue = defineAsyncComponent(
  () => import("@/components/icons/IconPlusBlue.vue")
);

const IconPlusNoColor = defineAsyncComponent(
  () => import("@/components/icons/IconPlusNoColor.vue")
);

const IconPlusSmall = defineAsyncComponent(
  () => import("@/components/icons/IconPlusSmall.vue")
);

const IconReplace = defineAsyncComponent(
  () => import("@/components/icons/IconReplace.vue")
);

const IconDeals = defineAsyncComponent(
  () => import("@/components/icons/sidebar/IconDeals.vue")
);

const IconDealsUnavailable = defineAsyncComponent(
  () => import("@/components/icons/IconDealsUnavailable.vue")
);

const IconBusinessProfilesUnavailable = defineAsyncComponent(
  () => import("@/components/icons/IconBusinessProfilesUnavailable.vue")
);

const IconEmptyState = defineAsyncComponent(
  () => import("@/components/icons/IconEmptyState.vue")
);

const IconDetails = defineAsyncComponent(
  () => import("@/components/icons/IconDetails.vue")
);

const IconTasks = defineAsyncComponent(
  () => import("@/components/icons/sidebar/IconTasks.vue")
);

const IconCompanies = defineAsyncComponent(
  () => import("@/components/icons/sidebar/IconCompanies.vue")
);

const IconFunders = defineAsyncComponent(
  () => import("@/components/icons/sidebar/IconFunders.vue")
);

const IconFundersPeople = defineAsyncComponent(
  () => import("@/components/icons/IconFundersPeople.vue")
);

const IconBusinesses = defineAsyncComponent(
  () => import("@/components/icons/sidebar/IconBusinesses.vue")
);

const IconRole = defineAsyncComponent(
  () => import("@/components/icons/sidebar/IconRole.vue")
);

const IconNotifications = defineAsyncComponent(
  () => import("@/components/icons/sidebar/IconNotifications.vue")
);

const IconProfile = defineAsyncComponent(
  () => import("@/components/icons/sidebar/IconProfile.vue")
);

const IconProfileNoBg = defineAsyncComponent(
  () => import("@/components/icons/sidebar/IconProfileNoBg.vue")
);

const IconProfileSmall = defineAsyncComponent(
  () => import("@/components/icons/IconProfileSmall.vue")
);

const IconProfileSmallNoBackground = defineAsyncComponent(
  () => import("@/components/icons/IconProfileSmallNoBackground.vue")
);

const IconHorizontalDots = defineAsyncComponent(
  () => import("@/components/icons/IconHorizontalDots.vue")
);

const IconLendflowLogoRound = defineAsyncComponent(
  () => import("@/components/icons/IconLendflowLogoRound.vue")
);

const IconWidgetBranding = defineAsyncComponent(
  () =>
    import("@/components/icons/sidebar/customization/IconWidgetBranding.vue")
);

const IconWidgetNoColor = defineAsyncComponent(
  () => import("@/components/icons/sidebar/customization/IconWidgetNoColor.vue")
);

const IconEmailBranding = defineAsyncComponent(
  () => import("@/components/icons/sidebar/customization/IconEmail.vue")
);

const IconLineOfCredit = defineAsyncComponent(
  () => import("@/components/icons/fpProductTypes/IconLineOfCredit.vue")
);

const IconArloc2 = defineAsyncComponent(
  () => import("@/components/icons/fpProductTypes/IconArloc.vue")
);

const IconErc = defineAsyncComponent(
  () => import("@/components/icons/fpProductTypes/IconErc.vue")
);

const IconTermLoan2 = defineAsyncComponent(
  () => import("@/components/icons/fpProductTypes/IconTermLoan.vue")
);

const IconReceivablesPurchase = defineAsyncComponent(
  () => import("@/components/icons/fpProductTypes/IconReceivablesPurchase.vue")
);

const IconEquipmentFinancing = defineAsyncComponent(
  () => import("@/components/icons/fpProductTypes/IconEquipmentFinancing.vue")
);

const IconActivity = defineAsyncComponent(
  () => import("@/components/icons/sidebar/deals/IconActivity.vue")
);

const IconDealProgress = defineAsyncComponent(
  () => import("@/components/icons/sidebar/deals/IconDealProgress.vue")
);

const IconFullApp = defineAsyncComponent(
  () => import("@/components/icons/sidebar/deals/IconFullApp.vue")
);

const IconDocuments = defineAsyncComponent(
  () => import("@/components/icons/sidebar/deals/IconDocuments.vue")
);

const IconHistory = defineAsyncComponent(
  () => import("@/components/icons/sidebar/deals/IconHistory.vue")
);

const IconOrchestration = defineAsyncComponent(
  () => import("@/components/icons/IconOrchestration.vue")
);

const IconOwnerSmall = defineAsyncComponent(
  () => import("@/components/icons/IconOwnerSmall.vue")
);

const IconAccount = defineAsyncComponent(
  () => import("@/components/icons/sidebar/profile/IconAccount.vue")
);

const IconRelations = defineAsyncComponent(
  () => import("@/components/icons/business/relations/IconRelations.vue")
);

const IconRelationBusiness = defineAsyncComponent(
  () => import("@/components/icons/business/relations/IconRelationBusiness.vue")
);

const IconRelationIndividual = defineAsyncComponent(
  () =>
    import("@/components/icons/business/relations/IconRelationIndividual.vue")
);

const IconRelationParent = defineAsyncComponent(
  () => import("@/components/icons/business/relations/IconRelationParent.vue")
);

const IconOrganization = defineAsyncComponent(
  () => import("@/components/icons/sidebar/profile/IconOrganization.vue")
);

const IconOrganizationWhite = defineAsyncComponent(
  () => import("@/components/icons/IconOrganizationWhite.vue")
);

const IconDataServices = defineAsyncComponent(
  () => import("@/components/icons/IconDataServices.vue")
);

const IconEmployees = defineAsyncComponent(
  () => import("@/components/icons/sidebar/profile/IconEmployees.vue")
);

const IconProfileNotifications = defineAsyncComponent(
  () =>
    import("@/components/icons/sidebar/profile/IconProfileNotifications.vue")
);

const IconTemplates = defineAsyncComponent(
  () => import("@/components/icons/sidebar/profile/IconTemplates.vue")
);

const IconWorkflow = defineAsyncComponent(
  () => import("@/components/icons/sidebar/profile/IconWorkflow.vue")
);

const IconWorkflowLinear = defineAsyncComponent(
  () => import("@/components/icons/workflow/IconWorkflowLinear.vue")
);

const IconWorkflowDynamic = defineAsyncComponent(
  () => import("@/components/icons/workflow/IconWorkflowDynamic.vue")
);

const IconGlobe = defineAsyncComponent(
  () => import("@/components/icons/IconGlobe.vue")
);

const IconStips = defineAsyncComponent(
  () => import("@/components/icons/sidebar/profile/IconStips.vue")
);

const IconOrchestrationColor = defineAsyncComponent(
  () => import("@/components/icons/sidebar/profile/IconOrchestrationColor.vue")
);

const IconEmailIntegration = defineAsyncComponent(
  () => import("@/components/icons/sidebar/IconEmailIntegration.vue")
);

const IconTable = defineAsyncComponent(
  () => import("@/components/icons/deals/IconTable.vue")
);

const IconReport = defineAsyncComponent(
  () => import("@/components/icons/deals/IconReport.vue")
);

const IconOnboarding = defineAsyncComponent(
  () => import("@/components/icons/sidebar/role/IconOnboarding.vue")
);

const IconReporting = defineAsyncComponent(
  () => import("@/components/icons/sidebar/role/IconReporting.vue")
);

const IconDropdownArrow = defineAsyncComponent(
  () => import("@/components/icons/IconDropdownArrow.vue")
);

const IconBriefcase = defineAsyncComponent(
  () => import("@/components/icons/IconBriefcase.vue")
);

const IconBriefcaseChecked = defineAsyncComponent(
  () => import("@/components/icons/IconBriefcaseChecked.vue")
);

const IconChatBubble = defineAsyncComponent(
  () => import("@/components/icons/IconChatBubble.vue")
);

const IconPieChart = defineAsyncComponent(
  () => import("@/components/icons/IconPieChart.vue")
);

const IconBullet = defineAsyncComponent(
  () => import("@/components/icons/IconBullet.vue")
);

const IconCalendar = defineAsyncComponent(
  () => import("@/components/icons/IconCalendar.vue")
);

const IconShowList = defineAsyncComponent(
  () => import("@/components/icons/IconShowList.vue")
);

const IconFullscreen = defineAsyncComponent(
  () => import("@/components/icons/IconFullscreen.vue")
);

const IconLocation = defineAsyncComponent(
  () => import("@/components/icons/IconLocation.vue")
);

const IconMore = defineAsyncComponent(
  () => import("@/components/icons/IconMore.vue")
);

const IconListChecked = defineAsyncComponent(
  () => import("@/components/icons/IconListChecked.vue")
);

const IconScorecards = defineAsyncComponent(
  () => import("@/components/icons/IconScorecards.vue")
);

const IconUSA = defineAsyncComponent(
  () => import("@/components/icons/IconUSA.vue")
);

const IconUSARec = defineAsyncComponent(
  () => import("@/components/icons/IconUSARec.vue")
);

const IconGBPRect = defineAsyncComponent(
  () => import("@/components/icons/IconGBPRec.vue")
);

const IconCanada = defineAsyncComponent(
  () => import("@/components/icons/IconCanada.vue")
);

const IconExclamationMarkRound = defineAsyncComponent(
  () => import("@/components/icons/IconExclamationMarkRound.vue")
);

const IconSuccessRound = defineAsyncComponent(
  () => import("@/components/icons/IconSuccessRound.vue")
);

const IconSuccessRoundBig = defineAsyncComponent(
  () => import("@/components/icons/IconSuccessRoundBig.vue")
);

const IconSuccessBigGreen = defineAsyncComponent(
  () => import("@/components/icons/IconSuccessBigGreen.vue")
);

const IconFailure = defineAsyncComponent(
  () => import("@/components/icons/IconFailure.vue")
);

const IconFailBigRed = defineAsyncComponent(
  () => import("@/components/icons/IconFailBigRed.vue")
);

const IconAddress = defineAsyncComponent(
  () => import("@/components/icons/IconAddress.vue")
);

const IconAddressNoFill = defineAsyncComponent(
  () => import("@/components/icons/IconAddressNoFill.vue")
);

const IconAddressCommercial = defineAsyncComponent(
  () => import("@/components/icons/IconAddressCommercial.vue")
);

const IconAddressResidential = defineAsyncComponent(
  () => import("@/components/icons/IconAddressResidential.vue")
);

const IconAddressDeliverable = defineAsyncComponent(
  () => import("@/components/icons/IconAddressDeliverable.vue")
);

const IconWebsite = defineAsyncComponent(
  () => import("@/components/icons/IconWebsite.vue")
);

const IconMap = defineAsyncComponent(
  () => import("@/components/icons/IconMap.vue")
);

const IconEdit = defineAsyncComponent(
  () => import("@/components/icons/IconEdit.vue")
);

const IconEmail = defineAsyncComponent(
  () => import("@/components/icons/IconEmail.vue")
);

const IconEmailNoColor = defineAsyncComponent(
  () => import("@/components/icons/IconEmailNoColor.vue")
);

const IconTelephone = defineAsyncComponent(
  () => import("@/components/icons/IconTelephone.vue")
);

const IconTelephoneNoColor = defineAsyncComponent(
  () => import("@/components/icons/IconTelephoneNoColor.vue")
);

const IconFax = defineAsyncComponent(
  () => import("@/components/icons/IconFax.vue")
);

const IconTelefax = defineAsyncComponent(
  () => import("@/components/icons/IconTelefax.vue")
);

const IconBirthday = defineAsyncComponent(
  () => import("@/components/icons/IconBirthday.vue")
);

const IconDocumentFile = defineAsyncComponent(
  () => import("@/components/icons/deals/IconDocumentFile.vue")
);

const IconClipboard = defineAsyncComponent(
  () => import("@/components/icons/deals/IconClipboard.vue")
);

const IconUploadFile = defineAsyncComponent(
  () => import("@/components/icons/deals/IconUploadFile.vue")
);

const IconHome = defineAsyncComponent(
  () => import("@/components/icons/IconHome.vue")
);

const IconInstitution = defineAsyncComponent(
  () => import("@/components/icons/IconInstitution.vue")
);

const IconAccountNumber = defineAsyncComponent(
  () => import("@/components/icons/IconAccountNumber.vue")
);

const IconWarningBlue = defineAsyncComponent(
  () => import("@/components/icons/IconWarningBlue.vue")
);

const IconSettingsBlue = defineAsyncComponent(
  () => import("@/components/icons/IconSettingsBlue.vue")
);

const IconInfoBlue = defineAsyncComponent(
  () => import("@/components/icons/IconInfoBlue.vue")
);

const IconFlagBlue = defineAsyncComponent(
  () => import("@/components/icons/IconFlagBlue.vue")
);

const IconLocationBlue = defineAsyncComponent(
  () => import("@/components/icons/IconLocationBlue.vue")
);

const IconPeopleBlue = defineAsyncComponent(
  () => import("@/components/icons/IconPeopleBlue.vue")
);

const IconUser = defineAsyncComponent(
  () => import("@/components/icons/IconUser.vue")
);

const IconPdf = defineAsyncComponent(
  () => import("@/components/icons/fileTypes/IconPdf.vue")
);

const IconLink = defineAsyncComponent(
  () => import("@/components/icons/IconLink.vue")
);

const IconLinkNoColor = defineAsyncComponent(
  () => import("@/components/icons/IconLinkNoColor.vue")
);

const IconLinkSmall = defineAsyncComponent(
  () => import("@/components/icons/IconLinkSmall.vue")
);

const IconLinkFull = defineAsyncComponent(
  () => import("@/components/icons/IconLinkFull.vue")
);

const IconDocusign = defineAsyncComponent(
  () => import("@/components/icons/IconDocusign.vue")
);

const IconApplicantInfo = defineAsyncComponent(
  () => import("@/components/icons/IconApplicantInfo.vue")
);

const IconPlaidLarge = defineAsyncComponent(
  () => import("@/components/icons/IconPlaidLarge.vue")
);

const IconCodatLarge = defineAsyncComponent(
  () => import("@/components/icons/IconCodatLarge.vue")
);

const IconResumeApp = defineAsyncComponent(
  () => import("@/components/icons/IconResumeApp.vue")
);

const IconPersonalInfo = defineAsyncComponent(
  () => import("@/components/icons/IconPersonalInfo.vue")
);

const IconMiddesk = defineAsyncComponent(
  () => import("@/components/icons/IconMiddesk.vue")
);

const IconCollapse = defineAsyncComponent(
  () => import("@/components/icons/IconCollapse.vue")
);

const IconExpandDocument = defineAsyncComponent(
  () => import("@/components/icons/IconExpandDocument.vue")
);

const IconCollapseDocument = defineAsyncComponent(
  () => import("@/components/icons/IconCollapseDocument.vue")
);

const IconDeleteWhite = defineAsyncComponent(
  () => import("@/components/icons/IconDeleteWhite.vue")
);

const IconCircleAdd = defineAsyncComponent(
  () => import("@/components/icons/IconCircleAdd.vue")
);

const IconDoc = defineAsyncComponent(
  () => import("@/components/icons/fileTypes/IconDoc.vue")
);

const IconXls = defineAsyncComponent(
  () => import("@/components/icons/fileTypes/IconXls.vue")
);

const IconPdfSmall = defineAsyncComponent(
  () => import("@/components/icons/fileTypes/IconPdfSmall.vue")
);

const IconDocSmall = defineAsyncComponent(
  () => import("@/components/icons/fileTypes/IconDocSmall.vue")
);

const IconXlsSmall = defineAsyncComponent(
  () => import("@/components/icons/fileTypes/IconXlsSmall.vue")
);

const IconImgSmall = defineAsyncComponent(
  () => import("@/components/icons/fileTypes/IconImgSmall.vue")
);

const IconReload = defineAsyncComponent(
  () => import("@/components/icons/IconReload.vue")
);

const IconIntegrations = defineAsyncComponent(
  () => import("@/components/icons/IconIntegrations.vue")
);

const IconEmptyStateIP = defineAsyncComponent(
  () => import("@/components/icons/IconEmptyStateIP.vue")
);

const IconReports = defineAsyncComponent(
  () => import("@/components/icons/IconReports.vue")
);

const IconCustomize = defineAsyncComponent(
  () => import("@/components/icons/IconCustomize.vue")
);

const IconCamera = defineAsyncComponent(
  () => import("@/components/icons/IconCamera.vue")
);

const IconChart = defineAsyncComponent(
  () => import("@/components/icons/IconChart.vue")
);

const IconChartDisabled = defineAsyncComponent(
  () => import("@/components/icons/IconChartDisabled.vue")
);

const IconJs = defineAsyncComponent(
  () => import("@/components/icons/IconJs.vue")
);

const IconQuestionMark = defineAsyncComponent(
  () => import("@/components/icons/IconQuestionMark.vue")
);

const IconStar = defineAsyncComponent(
  () => import("@/components/icons/IconStar.vue")
);

const IconStarOutlined = defineAsyncComponent(
  () => import("@/components/icons/IconStarOutlined.vue")
);

const IconStarCircle = defineAsyncComponent(
  () => import("@/components/icons/IconStarCircle.vue")
);

const IconScoreStar = defineAsyncComponent(
  () => import("@/components/icons/IconScoreStar.vue")
);

const IconBusinessCredit = defineAsyncComponent(
  () => import("@/components/icons/IconBusinessCredit.vue")
);

const IconChevronDown = defineAsyncComponent(
  () => import("@/components/icons/deals/IconChevronDown.vue")
);

const IconChevronUp = defineAsyncComponent(
  () => import("@/components/icons/deals/IconChevronUp.vue")
);

const IconChevronLeft = defineAsyncComponent(
  () => import("@/components/icons/deals/IconChevronLeft.vue")
);

const IconX = defineAsyncComponent(
  () => import("@/components/icons/deals/IconX.vue")
);

const IconXCircle = defineAsyncComponent(
  () => import("@/components/icons/IconXCircle.vue")
);

const IconXLarge = defineAsyncComponent(
  () => import("@/components/icons/deals/IconXLarge.vue")
);

const IconCopyLink = defineAsyncComponent(
  () => import("@/components/icons/deals/IconCopyLink.vue")
);

const IconCopyLinkNoBg = defineAsyncComponent(
  () => import("@/components/icons/deals/IconCopyLinkNoBg.vue")
);

const IconCollapseAll = defineAsyncComponent(
  () => import("@/components/CollapseAllIcon.vue")
);

const IconExpandAll = defineAsyncComponent(
  () => import("@/components/ExpandAllIcon.vue")
);

const IconScores = defineAsyncComponent(
  () => import("@/components/icons/IconScores.vue")
);

const IconArloc = defineAsyncComponent(
  () => import("@/components/icons/deals/IconArloc.vue")
);

const IconLoc = defineAsyncComponent(
  () => import("@/components/icons/deals/IconLoc.vue")
);

const IconRecPurchase = defineAsyncComponent(
  () => import("@/components/icons/deals/IconRecPurchase.vue")
);

const IconTermLoan = defineAsyncComponent(
  () => import("@/components/icons/deals/IconTermLoan.vue")
);

const IconTrashCan = defineAsyncComponent(
  () => import("@/components/icons/deals/IconTrashCan.vue")
);

const IconTrashOutline = defineAsyncComponent(
  () => import("@/components/icons/IconTrashCanOutline.vue")
);

const IconUnchecked = defineAsyncComponent(
  () => import("@/components/icons/stips/IconUnchecked.vue")
);

const IconChecked = defineAsyncComponent(
  () => import("@/components/icons/stips/IconChecked.vue")
);

const IconNotChecked = defineAsyncComponent(
  () => import("@/components/icons/IconNotChecked.vue")
);

const IconCheckedSmall = defineAsyncComponent(
  () => import("@/components/icons/IconCheckedSmall.vue")
);

const IconSecure = defineAsyncComponent(
  () => import("@/components/icons/IconSecure.vue")
);

const IconAnalyticsReports = defineAsyncComponent(
  () => import("@/components/icons/IconAnalyticsReports.vue")
);

const IconPalette = defineAsyncComponent(
  () => import("@/components/icons/IconPalette.vue")
);

const IconFooter = defineAsyncComponent(
  () => import("@/components/icons/IconFooter.vue")
);

const IconAddFile = defineAsyncComponent(
  () => import("@/components/icons/deals/IconAddFile.vue")
);

const IconPlusCircle = defineAsyncComponent(
  () => import("@/components/icons/IconPlusCircle.vue")
);

const IconNotes = defineAsyncComponent(
  () => import("@/components/icons/IconNotes.vue")
);

const IconPlaid = defineAsyncComponent(
  () => import("@/components/icons/IconPlaid.vue")
);

const IconArrowUp = defineAsyncComponent(
  () => import("@/components/icons/IconArrowUp.vue")
);

const IconInfo = defineAsyncComponent(
  () => import("@/components/icons/IconInfo.vue")
);

const IconSfConfigure = defineAsyncComponent(
  () => import("@/components/icons/IconSfConfigure.vue")
);

const IconSfConfigureInactive = defineAsyncComponent(
  () => import("@/components/icons/IconSfConfigureInactive.vue")
);

const IconAdd = defineAsyncComponent(
  () => import("@/components/icons/deals/IconAdd.vue")
);

const IconAddSmall = defineAsyncComponent(
  () => import("@/components/icons/deals/IconAddSmall.vue")
);

const IconNotFound = defineAsyncComponent(
  () => import("@/components/icons/IconNotFound.vue")
);

const IconPin = defineAsyncComponent(
  () => import("@/components/icons/IconPin.vue")
);

const IconWarning = defineAsyncComponent(
  () => import("@/components/icons/IconWarning.vue")
);

const IconWarningSmall = defineAsyncComponent(
  () => import("@/components/icons/IconWarningSmall.vue")
);

const IconTracking = defineAsyncComponent(
  () => import("@/components/icons/sidebar/deals/IconTracking.vue")
);

const IconUploadFileMinimal = defineAsyncComponent(
  () => import("@/components/icons/deals/IconUploadFileMinimal.vue")
);

const IconMagnifyingGlass = defineAsyncComponent(
  () => import("@/components/icons/IconMagnifyingGlass.vue")
);

const IconNotesEmpty = defineAsyncComponent(
  () => import("@/components/icons/IconNotesEmpty.vue")
);

const RiskTiersList = defineAsyncComponent(
  () => import("@/components/ui/RiskTiersList.vue")
);

const PersonaFailureReason = defineAsyncComponent(
  () =>
    import("@/views/businessCreditDeals/components/PersonaFailureReason.vue")
);

const EkataDataField = defineAsyncComponent(
  () => import("@/views/deals/components/EkataDataField.vue")
);

const CFADynamicTable = defineAsyncComponent(
  () => import("@/views/deals/components/CFADynamicTable.vue")
);

const CFAList = defineAsyncComponent(
  () => import("@/views/deals/components/CFAList.vue")
);

const EkataWarningsField = defineAsyncComponent(
  () => import("@/views/deals/components/EkataWarningsField.vue")
);

const SuccessIndicator = defineAsyncComponent(
  () => import("@/components/ui/SuccessIndicator.vue")
);

const DunAndBradstreetApiIcon = defineAsyncComponent(
  () => import("@/components/icons/api/dunandbradstreet.vue")
);

const EkataApiIcon = defineAsyncComponent(
  () => import("@/components/icons/api/ekata.vue")
);

const LexisNexisApiIcon = defineAsyncComponent(
  () => import("@/components/icons/api/lexisnexis.vue")
);

const ExperianApiIcon = defineAsyncComponent(
  () => import("@/components/icons/api/experian.vue")
);

const InscribeApiIcon = defineAsyncComponent(
  () => import("@/components/icons/api/inscribe.vue")
);

const MiddeskApiIcon = defineAsyncComponent(
  () => import("@/components/icons/api/middesk.vue")
);

const OcrolusApiIcon = defineAsyncComponent(
  () => import("@/components/icons/api/ocrolus.vue")
);

const PersonaApiIcon = defineAsyncComponent(
  () => import("@/components/icons/api/persona.vue")
);

const SentilinkApiIcon = defineAsyncComponent(
  () => import("@/components/icons/api/sentilink.vue")
);

const SocureApiIcon = defineAsyncComponent(
  () => import("@/components/icons/api/socure.vue")
);

const PlaidApiIcon = defineAsyncComponent(
  () => import("@/components/icons/api/plaid.vue")
);

const ClearApiIcon = defineAsyncComponent(
  () => import("@/components/icons/api/clear.vue")
);

const EquifaxApiIcon = defineAsyncComponent(
  () => import("@/components/icons/api/equifax.vue")
);

const CodatApiIcon = defineAsyncComponent(
  () => import("@/components/icons/api/codat.vue")
);

const RutterApiIcon = defineAsyncComponent(
  () => import("@/components/icons/api/rutter.vue")
);

const MoneyThumbApiIcon = defineAsyncComponent(
  () => import("@/components/icons/api/moneythumb.vue")
);

const ActionMenuItem = defineAsyncComponent(
  () => import("@/components/ui/ActionMenuItem.vue")
);

const IconBackgroundImage = defineAsyncComponent(
  () => import("@/components/icons/emailCustomization/IconBackgroundImage.vue")
);

const IconBackgroundNone = defineAsyncComponent(
  () => import("@/components/icons/emailCustomization/IconBackgroundNone.vue")
);

const LfContentLoader = defineAsyncComponent(
  () => import("@/components/ui/LfContentLoader.vue")
);

const LogicalIndicator = defineAsyncComponent(
  () => import("@/components/LogicalIndicator.vue")
);

const LfLogicalSwitch = defineAsyncComponent(
  () => import("@/components/ui/inputs/LfLogicalSwitch.vue")
);

const LfButtonSelectArray = defineAsyncComponent(
  () => import("@/components/ui/inputs/LfButtonSelectArray.vue")
);

const LfButtonSelectString = defineAsyncComponent(
  () => import("@/components/ui/inputs/LfButtonSelectString.vue")
);

const ServiceNode = defineAsyncComponent(
  () => import("@/views/profile/components/dataOrchestration/ServiceNode.vue")
);

const ConditionalArgumentNode = defineAsyncComponent(
  () =>
    import(
      "@/views/profile/components/dataOrchestration/ConditionalArgumentNode.vue"
    )
);

const DoStep = defineAsyncComponent(
  () => import("@/views/profile/components/dataOrchestration/DoStep.vue")
);

const DoMonthPicker = defineAsyncComponent(
  () =>
    import(
      "@/views/profile/components/dataOrchestration/configPanel/DOMonthPicker.vue"
    )
);

const IconHourglass = defineAsyncComponent(
  () => import("@/components/icons/IconHourglass.vue")
);

const IconClock = defineAsyncComponent(
  () => import("@/components/icons/IconClock.vue")
);

const IconDragHandle = defineAsyncComponent(
  () => import("@/components/icons/IconDragHandle.vue")
);

const IconCircle = defineAsyncComponent(
  () => import("@/components/icons/IconCircle.vue")
);

const IconInfoSmall = defineAsyncComponent(
  () => import("@/components/icons/IconInfoSmall.vue")
);

const IconSandboxWhite = defineAsyncComponent(
  () => import("@/components/icons/IconSandboxWhite.vue")
);

const IconSandbox = defineAsyncComponent(
  () => import("@/components/icons/IconSandbox.vue")
);

const IconTrigger = defineAsyncComponent(
  () => import("@/components/icons/IconTrigger.vue")
);

const PlacementMethodDropdown = defineAsyncComponent(
  () => import("@/components/deals/placement/PlacementMethodDropdown.vue")
);

const IconAddStage = defineAsyncComponent(
  () => import("@/components/icons/IconAddStage.vue")
);

const IconPercentage = defineAsyncComponent(
  () => import("@/components/icons/IconPercentage.vue")
);

const IconOffer = defineAsyncComponent(
  () => import("@/components/icons/IconOffer.vue")
);

const IconApproval = defineAsyncComponent(
  () => import("@/components/icons/IconApproval.vue")
);

const IconFunding = defineAsyncComponent(
  () => import("@/components/icons/IconFunding.vue")
);

const IconFullApplication = defineAsyncComponent(
  () => import("@/components/icons/IconFullApplication.vue")
);

const StageEditButton = defineAsyncComponent(
  () => import("@/components/deals/workflow/StageEditButton.vue")
);

const IconStatusActive = defineAsyncComponent(
  () => import("@/components/icons/status/IconStatusActive.vue")
);

const IconCheck = defineAsyncComponent(
  () => import("@/components/icons/IconCheck.vue")
);

const IconStatusComplete = defineAsyncComponent(
  () => import("@/components/icons/status/IconStatusComplete.vue")
);

const IconStatusIncomplete = defineAsyncComponent(
  () => import("@/components/icons/status/IconStatusIncomplete.vue")
);

const WorkflowCategoryPill = defineAsyncComponent(
  () => import("@/components/ui/WorkflowCategoryPill.vue")
);

const ViewWrapper = defineAsyncComponent(
  () => import("@/views/ViewWrapper.vue")
);

const IconAddTab = defineAsyncComponent(
  () => import("@/components/icons/workflow/IconAddTab.vue")
);

const WorkflowTabEditButton = defineAsyncComponent(
  () => import("@/components/deals/workflow/TabsEditButton.vue")
);

const IconGear = defineAsyncComponent(
  () => import("@/components/icons/IconGear.vue")
);

const DropdownChevron = defineAsyncComponent(
  () => import("@/components/ui/DropdownChevron.vue")
);

const IconLinkedIn = defineAsyncComponent(
  () => import("@/components/icons/brands/IconLinkedIn.vue")
);

const IconTwitter = defineAsyncComponent(
  () => import("@/components/icons/brands/IconTwitter.vue")
);

const IconFacebook = defineAsyncComponent(
  () => import("@/components/icons/brands/IconFacebook.vue")
);

const IconCheckmarkRoundBlue = defineAsyncComponent(
  () => import("@/components/icons/IconCheckmarkRoundBlue.vue")
);

const IconProcessing = defineAsyncComponent(
  () => import("@/components/icons/IconProcessing.vue")
);

const IconIRS = defineAsyncComponent(
  () => import("@/components/icons/IconIRS.vue")
);

const IconExperian = defineAsyncComponent(
  () => import("@/components/icons/IconExperian.vue")
);

const IconLock = defineAsyncComponent(
  () => import("@/components/icons/IconLock.vue")
);

const IconShow = defineAsyncComponent(
  () => import("@/components/icons/IconShow.vue")
);

const IconSwitch = defineAsyncComponent(
  () => import("@/components/icons/IconSwitch.vue")
);

const IconAndAbove = defineAsyncComponent(
  () => import("@/components/icons/IconAndAbove.vue")
);

const AvailabilityButton = defineAsyncComponent(
  () => import("@/components/ui/AvailabilityButton.vue")
);

const IconArrowRight = defineAsyncComponent(
  () => import("@/components/icons/IconArrowRight.vue")
);

const StageBlock = defineAsyncComponent(
  () => import("@/components/deals/workflow/StageBlock.vue")
);

const IconAddBlueRoundBig = defineAsyncComponent(
  () => import("@/components/icons/IconAddBlueRoundBig.vue")
);

const IconBlocks = defineAsyncComponent(
  () => import("@/components/icons/workflow/IconBlocks.vue")
);

const IconAsterisk = defineAsyncComponent(
  () => import("@/components/icons/IconAsterisk.vue")
);

const IconBanknote = defineAsyncComponent(
  () => import("@/components/icons/IconBanknote.vue")
);

const IconCreditCardArrow = defineAsyncComponent(
  () => import("@/components/icons/IconCreditCardArrow.vue")
);

const IconFundedAmount = defineAsyncComponent(
  () => import("@/components/icons/IconFundedAmount.vue")
);

const IconPercentageBlueBg = defineAsyncComponent(
  () => import("@/components/icons/IconPercentageBlueBg.vue")
);

const IconBank = defineAsyncComponent(
  () => import("@/components/icons/IconBank.vue")
);

const IDescription = defineAsyncComponent(
  () => import("@/components/icons/IconDescription.vue")
);

const NotAvailableComponent = defineAsyncComponent(
  () => import("@/components/ui/NotAvailable.vue")
);

const IconSettingsLoading = defineAsyncComponent(
  () => import("@/components/icons/IconSettingsLoading.vue")
);

const IconSettingsLoadingRed = defineAsyncComponent(
  () => import("@/components/icons/IconSettingsLoadingRed.vue")
);

const IconPersonLocation = defineAsyncComponent(
  () => import("@/components/icons/IconPersonLocation.vue")
);

const IconIdentity = defineAsyncComponent(
  () => import("@/components/icons/IconIdentity.vue")
);

const IconDollarCoin = defineAsyncComponent(
  () => import("@/components/icons/IconDollarCoin.vue")
);

const IconMailingAddress = defineAsyncComponent(
  () => import("@/components/icons/IconMailingAddress.vue")
);

const IconPersons = defineAsyncComponent(
  () => import("@/components/icons/IconPersons.vue")
);

const IconSearch = defineAsyncComponent(
  () => import("@/components/icons/IconSearch.vue")
);

const CreateButton = defineAsyncComponent(
  () => import("@/components/ui/buttons/CreateButton.vue")
);

const SelectorButton = defineAsyncComponent(
  () => import("@/components/ui/buttons/SelectorButton.vue")
);

const IconDocument = defineAsyncComponent(
  () => import("@/components/icons/IconDocument.vue")
);

const IconPaste = defineAsyncComponent(
  () => import("@/components/icons/IconPaste.vue")
);

const IconThumbsUpSuccess = defineAsyncComponent(
  () => import("@/components/icons/IconThumbsUpSuccess.vue")
);

const LFAvatar = defineAsyncComponent(
  () => import("@/components/ui/LFAvatar.vue")
);

const IconToken = defineAsyncComponent(
  () => import("@/components/icons/IconToken.vue")
);

const ToggleAllIcon = defineAsyncComponent(
  () => import("@/components/ToggleAllIcon.vue")
);

export default {
  "lf-input": LfInput,
  "search-input": SearchInput,
  "lf-link": LfLink,
  "lf-textarea": LfTextarea,
  "lf-h1": LfHeading1,
  "lf-h2": LfHeading2,
  "lf-h3": LfHeading3,
  "lf-h4": LfHeading4,
  "ekata-data-field": EkataDataField,
  "ekata-warnings-field": EkataWarningsField,
  "success-indicator": SuccessIndicator,
  "failure-reasons": PersonaFailureReason,
  "primary-button": PrimaryButton,
  "outline-button": OutlineButton,
  "lf-date-picker": LfDatePicker,
  "date-time-input": DateTimeInput,
  "color-button": ColorButton,
  "lf-button": LfButton,
  "lf-tab": LfTab,
  "lf-pill": LfPill,
  "status-pill": StatusPill,
  "offer-status-pill": OfferStatusPill,
  "offer-pill-dropdown": OfferPillDropdown,
  "offer-contacted-via": OfferContactedVia,
  "offer-delete-button": OfferDeleteButton,
  "placement-status": PlacementStatus,
  "lf-pie-chart": LfPieChart,
  "assign-user-field": AssignUserField,
  "payment-frequency-dropdown": PaymentFrequencyDropdown,
  "lf-date": LfDate,
  "lf-dropdown": LfDropdown,
  "lf-dropdown-with-api-search": LfDropdownWithApiSearch,
  "lf-checkbox-dropdown": LfCheckboxDropdown,
  "lf-badge": LfBadge,
  "lf-chip": LfChip,
  "lf-status": LfStatus,
  "edit-button": EditButton,
  "button-create-offer": ButtonCreateOffer,
  "button-edit-offer": ButtonEditOffer,
  "offer-dropdown-menu": OfferDropdownMenu,
  "lf-currency": LfCurrency,
  "documents-list-item-action-btn": DocumentsListItemActionBtn,
  "user-profile": UserProfile,
  "edit-row-button": EditRowButton,
  "client-modal": ClientModal,
  "user-modal": UserModal,
  "assign-workflow-dropdown": AssignWorkflowDropdown,
  loader: Loader,
  "lf-external-link": LfExternalLink,
  "access-control": AccessControl,
  "lf-card": LfCard,
  "lf-scrollable-menu": LfScrollableMenu,
  "lf-round-button": RoundButton,
  "lf-circle-progress": CircleProgress,
  "lf-progress": Progress,
  "lf-details": LfDetails,
  "funder-modal": FunderModal,
  "funder-modal-table-button": FunderModalTableButton,
  "funder-user-modal": FunderUserModal,
  "contact-modal": ContactModal,
  "contact-modal-table-button": ContactModalTableButton,
  "sales-rep-modal": SalesRepModal,
  "sales-rep-modal-table-button": SalesRepModalTableButton,
  "download-service-button": DownloadServiceButton,
  "service-wrapper": ServiceWrapper,
  "cfa-dynamic-table": CFADynamicTable,
  "cfa-list": CFAList,
  "lf-switch": LfSwitch,
  "lf-button-toggle": LfButtonToggle,
  "lf-content-loader": LfContentLoader,
  "service-node": ServiceNode,
  "conditional-argument-node": ConditionalArgumentNode,
  "do-step": DoStep,
  "do-month-picker": DoMonthPicker,
  "icon-base": IconBase,
  "icon-dollar-coin": IconDollarCoin,
  "icon-mailing-address": IconMailingAddress,
  "icon-formula-view": IconFormulaView,
  "icon-reset": IconReset,
  "icon-restart": IconRestart,
  "icon-risk-tier-1": IconRiskTier1,
  "icon-risk-tier-2": IconRiskTier2,
  "icon-risk-tier-3": IconRiskTier3,
  "icon-risk-tier-4": IconRiskTier4,
  "icon-risk-tier-5": IconRiskTier5,
  "icon-risk-tier-6": IconRiskTier6,
  "icon-trashcan": IconTrashCan,
  "icon-trashcan-outline": IconTrashOutline,
  "icon-notes-empty": IconNotesEmpty,
  "icon-arrow-up": IconArrowUp,
  "icon-info": IconInfo,
  "icon-sf-configure": IconSfConfigure,
  "icon-sf-configure-inactive": IconSfConfigureInactive,
  "icon-close": IconClose,
  "icon-funded-offer": IconFundedOffer,
  "icon-email-enabled": IconEmailEnabled,
  "icon-email-disabled": IconEmailDisabled,
  "icon-id": IconId,
  "icon-expand": IconExpand,
  "icon-expand-white": IconExpandWhite,
  "icon-shrink": IconShrink,
  "icon-shrink-white": IconShrinkWhite,
  "icon-sort": IconSort,
  "icon-sort-arrow": IconSortArrow,
  "icon-sort-arrow-active": IconSortArrowActive,
  "icon-logo": IconLogo,
  "icon-logo-blue": IconLogoBlue,
  "icon-logo-white": IconLogoWhite,
  "icon-chevron-right": IconChevronRight,
  "icon-logo-expanded": IconLogoExpanded,
  "icon-logo-expanded-black": IconLogoExpandedBlack,
  "icon-copy": IconCopy,
  "icon-check-circle": IconCheckCircle,
  "icon-checked-gray": IconCheckedGray,
  "icon-show-pass": IconShowPass,
  "icon-show-pass-no-bg": IconShowPassNoBg,
  "icon-hide-pass": IconHidePass,
  "icon-copy-filled": IconCopyFilled,
  "icon-download": IconDownload,
  "icon-download-large": IconDownloadLarge,
  "icon-delete": IconDelete,
  "icon-delete-round": IconDeleteRound,
  "icon-delete-offer": IconDeleteOffer,
  "icon-vertical-dots": IconVerticalDots,
  "icon-file": IconFile,
  "icon-logout": IconLogout,
  "icon-profile-small": IconProfileSmall,
  "icon-profile-small-no-bg": IconProfileSmallNoBackground,
  "icon-chevron-down": IconChevronDown,
  "icon-chevron-up": IconChevronUp,
  "icon-chevron-left": IconChevronLeft,
  "icon-warning": IconWarning,
  "icon-warning-small": IconWarningSmall,
  "icon-collapse": IconCollapse,
  "icon-expand-document": IconExpandDocument,
  "icon-collapse-document": IconCollapseDocument,
  "icon-circled-add": IconCircleAdd,
  "icon-palette": IconPalette,
  "icon-footer": IconFooter,
  "icon-plus-circle": IconPlusCircle,
  "icon-notes": IconNotes,
  "icon-magnifying-glass": IconMagnifyingGlass,
  "icon-deals": IconDeals,
  "icon-deals-unavailable": IconDealsUnavailable,
  "icon-business-profiles-unavailable": IconBusinessProfilesUnavailable,
  "icon-empty-state": IconEmptyState,
  "icon-details": IconDetails,
  "icon-tasks": IconTasks,
  "icon-companies": IconCompanies,
  "icon-funders": IconFunders,
  "icon-funders-people": IconFundersPeople,
  "icon-businesses": IconBusinesses,
  "icon-role": IconRole,
  "icon-notifications": IconNotifications,
  "icon-profile": IconProfile,
  "icon-profile-no-bg": IconProfileNoBg,
  "icon-expandall": IconExpandAll,
  "icon-collapseall": IconCollapseAll,
  "icon-widget-branding": IconWidgetBranding,
  "icon-widget-no-color": IconWidgetNoColor,
  "icon-email-branding": IconEmailBranding,
  "icon-edit-white": IconEditWhite,
  "icon-delete-white": IconDeleteWhite,
  "icon-email-integration": IconEmailIntegration,
  "icon-activity": IconActivity,
  "icon-deal-progress": IconDealProgress,
  "icon-full-app": IconFullApp,
  "icon-documents": IconDocuments,
  "icon-history": IconHistory,
  "icon-orchestration": IconOrchestration,
  "icon-owner-small": IconOwnerSmall,
  "icon-account": IconAccount,
  "icon-relations": IconRelations,
  "icon-relation-business": IconRelationBusiness,
  "icon-relation-individual": IconRelationIndividual,
  "icon-relation-parent": IconRelationParent,
  "icon-organization": IconOrganization,
  "icon-organization-white": IconOrganizationWhite,
  "icon-data-services": IconDataServices,
  "icon-employees": IconEmployees,
  "icon-profile-notifications": IconProfileNotifications,
  "icon-templates": IconTemplates,
  "icon-workflow": IconWorkflow,
  "icon-workflow-linear": IconWorkflowLinear,
  "icon-workflow-dynamic": IconWorkflowDynamic,
  "icon-globe": IconGlobe,
  "icon-stips": IconStips,
  "icon-orchestration-color": IconOrchestrationColor,
  "icon-onboarding": IconOnboarding,
  "icon-reporting": IconReporting,
  "icon-business-credit": IconBusinessCredit,
  "icon-x": IconX,
  "icon-x-circle": IconXCircle,
  "icon-x-large": IconXLarge,
  "icon-round-increase": IconRoundIncrease,
  "icon-round-decrease": IconRoundDecrease,
  "icon-round-neutral": IconRoundNeutral,
  "icon-plus": IconPlus,
  "icon-plus-blue": IconPlusBlue,
  "icon-plus-no-color": IconPlusNoColor,
  "icon-plus-small": IconPlusSmall,
  "icon-dropdown-arrow": IconDropdownArrow,
  "icon-calendar": IconCalendar,
  "icon-show-list": IconShowList,
  "icon-fullscreen": IconFullscreen,
  "icon-location": IconLocation,
  "icon-briefcase": IconBriefcase,
  "icon-briefcase-checked": IconBriefcaseChecked,
  "icon-chat-bubble": IconChatBubble,
  "icon-pie-chart": IconPieChart,
  "icon-bullet": IconBullet,
  "icon-more": IconMore,
  "icon-list-checked": IconListChecked,
  "icon-scorecards": IconScorecards,
  "icon-us": IconUSA,
  "icon-usa-rec": IconUSARec,
  "icon-gbp-rec": IconGBPRect,
  "icon-ca": IconCanada,
  "icon-exclamation-mark-round": IconExclamationMarkRound,
  "icon-success-round": IconSuccessRound,
  "icon-success-round-big": IconSuccessRoundBig,
  "icon-success-big-green": IconSuccessBigGreen,
  "icon-failure": IconFailure,
  "icon-fail-big-red": IconFailBigRed,
  "icon-address": IconAddress,
  "icon-address-no-fill": IconAddressNoFill,
  "icon-address-commercial": IconAddressCommercial,
  "icon-address-residential": IconAddressResidential,
  "icon-address-deliverable": IconAddressDeliverable,
  "icon-website": IconWebsite,
  "icon-map": IconMap,
  "icon-edit": IconEdit,
  "icon-email": IconEmail,
  "icon-email-no-color": IconEmailNoColor,
  "icon-telephone": IconTelephone,
  "icon-telephone-no-color": IconTelephoneNoColor,
  "icon-fax": IconFax,
  "icon-telefax": IconTelefax,
  "icon-birthday": IconBirthday,
  "icon-document-file": IconDocumentFile,
  "icon-clipboard": IconClipboard,
  "icon-upload-file": IconUploadFile,
  "icon-home": IconHome,
  "icon-upload-file-minimal": IconUploadFileMinimal,
  "icon-institution": IconInstitution,
  "icon-account-number": IconAccountNumber,
  "icon-user": IconUser,
  "icon-pdf": IconPdf,
  "icon-link": IconLink,
  "icon-link-no-color": IconLinkNoColor,
  "icon-link-small": IconLinkSmall,
  "icon-link-full": IconLinkFull,
  "icon-docusign": IconDocusign,
  "icon-applicant-info": IconApplicantInfo,
  "icon-plaid-large": IconPlaidLarge,
  "icon-codat-large": IconCodatLarge,
  "icon-resume-app": IconResumeApp,
  "icon-personal-info": IconPersonalInfo,
  "icon-middesk": IconMiddesk,
  "icon-doc": IconDoc,
  "icon-xls": IconXls,
  "icon-reload": IconReload,
  "icon-integrations": IconIntegrations,
  "icon-empty-state-ip": IconEmptyStateIP,
  "icon-reports": IconReports,
  "icon-customize": IconCustomize,
  "icon-camera": IconCamera,
  "icon-chart": IconChart,
  "icon-chart-disabled": IconChartDisabled,
  "icon-doc-small": IconDocSmall,
  "icon-xls-small": IconXlsSmall,
  "icon-img-small": IconImgSmall,
  "icon-pdf-small": IconPdfSmall,
  "icon-js": IconJs,
  "icon-question-mark": IconQuestionMark,
  "icon-star": IconStar,
  "icon-star-outlined": IconStarOutlined,
  "icon-star-circle": IconStarCircle,
  "icon-score-star": IconScoreStar,
  "icon-add-plus": IconAdd,
  "icon-add-small": IconAddSmall,
  "icon-horizontal-dots": IconHorizontalDots,
  "icon-arloc": IconArloc,
  "icon-loc": IconLoc,
  "icon-rec-purchase": IconRecPurchase,
  "icon-term-loan": IconTermLoan,
  "icon-erc": IconErc,
  "icon-not-found": IconNotFound,
  "icon-pin": IconPin,
  "icon-copy-link": IconCopyLink,
  "icon-copy-link-no-bg": IconCopyLinkNoBg,
  "icon-not-checked": IconNotChecked,
  "icon-checked-small": IconCheckedSmall,
  "icon-secure": IconSecure,
  "icon-analytics-reports": IconAnalyticsReports,
  "icon-add-file": IconAddFile,
  "icon-line-of-credit": IconLineOfCredit,
  "icon-arloc-2": IconArloc2,
  "icon-term-loan-2": IconTermLoan2,
  "icon-receivables-purchase": IconReceivablesPurchase,
  "icon-equipment-financing": IconEquipmentFinancing,
  "icon-tracking": IconTracking,
  "icon-lendflow-logo-round": IconLendflowLogoRound,
  "icon-table": IconTable,
  "icon-report": IconReport,
  "icon-round-unchecked": IconUnchecked,
  "icon-round-checked": IconChecked,
  "icon-add-stage": IconAddStage,
  "icon-plaid": IconPlaid,
  "icon-irs": IconIRS,
  "icon-experian": IconExperian,
  "risk-tier-list": RiskTiersList,
  "action-menu-item": ActionMenuItem,
  "dnb-api-icon": DunAndBradstreetApiIcon,
  "ekata-api-icon": EkataApiIcon,
  "experian-api-icon": ExperianApiIcon,
  "inscribe-api-icon": InscribeApiIcon,
  "middesk-api-icon": MiddeskApiIcon,
  "ocrolus-api-icon": OcrolusApiIcon,
  "persona-api-icon": PersonaApiIcon,
  "sentilink-api-icon": SentilinkApiIcon,
  "clear-api-icon": ClearApiIcon,
  "socure-api-icon": SocureApiIcon,
  "lexisnexis-api-icon": LexisNexisApiIcon,
  "rutter-api-icon": RutterApiIcon,
  "plaid-api-icon": PlaidApiIcon,
  "equifax-api-icon": EquifaxApiIcon,
  "codat-api-icon": CodatApiIcon,
  "moneythumb-api-icon": MoneyThumbApiIcon,
  "icon-background-image": IconBackgroundImage,
  "icon-background-none": IconBackgroundNone,
  "logical-indicator": LogicalIndicator,
  "logical-switch": LfLogicalSwitch,
  "lf-button-select-array": LfButtonSelectArray,
  "lf-button-select-string": LfButtonSelectString,
  "icon-hourglass": IconHourglass,
  "icon-clock": IconClock,
  "icon-drag-handle": IconDragHandle,
  "icon-circle": IconCircle,
  "icon-info-small": IconInfoSmall,
  "icon-sandbox": IconSandbox,
  "placement-method-dropdown": PlacementMethodDropdown,
  "icon-sandbox-white": IconSandboxWhite,
  "icon-trigger": IconTrigger,
  "icon-scores": IconScores,
  "icon-percentage": IconPercentage,
  "icon-offer": IconOffer,
  "icon-approval": IconApproval,
  "icon-funding": IconFunding,
  "icon-full-application": IconFullApplication,
  "stage-edit-button": StageEditButton,
  "icon-status-active": IconStatusActive,
  "icon-status-complete": IconStatusComplete,
  "icon-status-incomplete": IconStatusIncomplete,
  "workflow-category-pill": WorkflowCategoryPill,
  "view-wrapper": ViewWrapper,
  "icon-add-tab": IconAddTab,
  "workflow-tab-edit-button": WorkflowTabEditButton,
  "icon-gear": IconGear,
  "dropdown-chevron": DropdownChevron,
  "icon-lock": IconLock,
  "icon-show": IconShow,
  "icon-switch": IconSwitch,
  "icon-and-above": IconAndAbove,
  "icon-linkedin": IconLinkedIn,
  "icon-twitter": IconTwitter,
  "icon-facebook": IconFacebook,
  "icon-checkmark-round-blue": IconCheckmarkRoundBlue,
  "icon-processing": IconProcessing,
  "icon-replace": IconReplace,
  "availability-button": AvailabilityButton,
  "icon-arrow-right": IconArrowRight,
  "stage-block": StageBlock,
  "icon-blocks": IconBlocks,
  "icon-add-blue-round-big": IconAddBlueRoundBig,
  "icon-check": IconCheck,
  "icon-asterisk": IconAsterisk,
  "icon-banknote": IconBanknote,
  "icon-funded-amount": IconFundedAmount,
  "icon-credit-card-arrow": IconCreditCardArrow,
  "icon-percentage-blue-bg": IconPercentageBlueBg,
  "icon-bank": IconBank,
  "icon-description": IDescription,
  "not-available": NotAvailableComponent,
  "icon-warning-blue": IconWarningBlue,
  "icon-settings-blue": IconSettingsBlue,
  "icon-info-blue": IconInfoBlue,
  "icon-flag-blue": IconFlagBlue,
  "icon-location-blue": IconLocationBlue,
  "icon-people-blue": IconPeopleBlue,
  "icon-settings-loading": IconSettingsLoading,
  "icon-settings-loading-red": IconSettingsLoadingRed,
  "icon-person-location": IconPersonLocation,
  "icon-identity": IconIdentity,
  "icon-search": IconSearch,
  "icon-persons": IconPersons,
  "create-button": CreateButton,
  "selector-button": SelectorButton,
  "icon-document": IconDocument,
  "icon-thumbs-up-success": IconThumbsUpSuccess,
  "lf-avatar": LFAvatar,
  "icon-token": IconToken,
  "icon-paste": IconPaste,
  "toggle-all-icon": ToggleAllIcon,
  "icon-curly-braces": IconCurlyBraces
};
